import React from "react"
import { graphql } from "gatsby"

const Page = ({ data }) => {
  return (
    <main>
      <h1>{data.page.title}</h1>
      <p>{data.page.description.description}</p>
      <p>{data.dieselGenerators.model}</p>
    </main>
  )
}

export const data = graphql`
  query pageQuery($id: String) {
    page: contentfulPage(id: { eq: $id }) {
      url
      title
      description {
        description
      }
    }
    dieselGenerators: allContentfulDieselGenerator {
      nodes {
        model
      }
    }
  }
`

export default Page
